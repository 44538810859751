<template>
  <div class="mt-1 mx-3">
    <salesmen> </salesmen>
  </div>
</template>

<script>
import Salesmen from "@/components/profile/brokers/agency-brokers/index.vue";

import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Salesmen,
  },
  created() {
    this.notifyQueue({
      text: "Produttori di Agenzia",
      path: "brokers.index2",
      level: 1,
    });
  },
};
</script>
